import jQuery from "jquery";

require("./scripts/_navigation");
require("./scripts/_hero");
require("./scripts/_swipers");
require("./scripts/_lightbox");
// require("./scripts/_fixed-header");

// require main styles
require("./styles/style.scss");
