import SimpleLightbox from "simplelightbox/dist/simple-lightbox.modules";

document.addEventListener("DOMContentLoaded", function () {
  const lightbox = new SimpleLightbox(".gallery .swiper-slide", {
    sourceAttr: "href",
    overlay: true,
    spinner: true,
    nav: true,
  });
});
