document.addEventListener("DOMContentLoaded", function () {
  const header = document.getElementById("masthead");
  const phoneCall = document.getElementById("phone-call");
  const hero = document.getElementById("hero");
  const footer = document.getElementById("footer");

  if (hero) {
    if (window.screen.width <= 1170) {
      const headerHeight = header.offsetHeight;
      const phoneCallHeight = phoneCall.offsetHeight;

      hero.style.minHeight = `calc(100vh - ${headerHeight / 10}rem - ${phoneCallHeight / 10}rem)`;
      footer.style.paddingBottom = `${phoneCallHeight / 10 + 2}rem`;
    } else {
      const headerHeight = header.offsetHeight;

      hero.style.minHeight = `calc(100vh - ${headerHeight / 10}rem)`;
    }
  }
});
