// Import Swiper and modules
import { Swiper, Navigation, Pagination, Autoplay } from "swiper/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

document.addEventListener("DOMContentLoaded", function () {
  const logotypesSlider = new Swiper(".logotypes .swiper-container", {
    loop: false,
    speed: 400,
    navigation: {
      nextEl: ".logotypes .swiper-button-next",
      prevEl: ".logotypes .swiper-button-prev",
    },
    // Responsive breakpoints
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      580: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1170: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
    },
  });

  const gallery = document.querySelector(".gallery");

  if (gallery) {
    const galleryOnPage = gallery.dataset.galleryOnPage;

    if (galleryOnPage == "strona-glowna") {
      const gallerySwiper = new Swiper(".gallery .swiper-container", {
        loop: false,
        speed: 400,
        navigation: {
          nextEl: ".gallery .swiper-button-next",
          prevEl: ".gallery .swiper-button-prev",
        },
        // Responsive breakpoints
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          580: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1170: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      });
    } else if (galleryOnPage == "realizacje") {
      const gallerySwiper = new Swiper(".gallery .swiper-container", {
        loop: false,
        speed: 400,
        navigation: {
          nextEl: ".gallery .swiper-button-next",
          prevEl: ".gallery .swiper-button-prev",
        },
        // Responsive breakpoints
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          580: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1170: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        },
      });
    }
  }
});
